import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import client from "../../hooks/Data.js";
import customClient from "../../hooks/customClient.js";
import ClipLoader from "react-spinners/ClipLoader";
import ButtonPrimary from "../../components/ButtonPrimary.js";
import useCookie from "../../hooks/Cookie.js";
import { WinnerUrl } from "../../hooks/Env.js";
import SetStat from "../../hooks/SetStat.js";
import confetti from "canvas-confetti";


const Place = () => {
  const [annonce, setAnnonce] = useState(null);
  const [isWinner, setIsWinner] = useState(false);
  const [ref, setRef] = useState(null);
  const [loading, setLoading] = useState(true);
  const [medias, setMedias] = useState(null);
  const [addEl, setAddEl] = useState(null);
  const [destination, setDestination] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [buttonColor, setButtonColor] = useState("#787878");
  const [idevent, setIdevent] = useState('');
  const [liked, setLiked] = useState(false);
  const [bookmarked, setBookmarked] = useState(false);
  const navigate = useNavigate();

  const formatWhatsAppMessage = useCallback((ref, campaignName) => {
    // Message pour les gagnants
    if (isWinner) {
      return encodeURIComponent(
        `Hello, Je m'appelle : (mettez votre prénom ici)\n Je suis le gagnant de (nom de la campagne).\nMon numéro gagnant est ${ref}`
      );
    }
    // test
    return encodeURIComponent(
      `Hello : (mettez votre prénom ici)\nJ'ai participé à (nom de la campagne) mais je n'ai pas gagné cette fois-ci.`
    );
  }, [isWinner]);

  const setDestUrl = useCallback(() => {
    if (annonce) {
      
      const whatsappMessage = formatWhatsAppMessage(ref, annonce.description);
      const url = `${WinnerUrl}?text=${whatsappMessage}`;
      /* console.log("url_des", annonce.url_des); */
      setDestination(url);

      /* if (isWinner) {
        const whatsappMessage = formatWhatsAppMessage(ref, annonce.description);
        const url = `${WinnerUrl}?text=${whatsappMessage}`;
        setDestination(url);
      } else {
        setDestination(annonce.url_des);
        console.log("url_des", annonce.url_des);
      } */
    }
  }, [isWinner, ref, annonce, formatWhatsAppMessage]);

  useEffect(() => {
    const hasPassedRedirect = sessionStorage.getItem('hasPassedRedirect') === 'true';
    const storedIdevent = sessionStorage.getItem('currentIdevent');
    
    if (!hasPassedRedirect) {
      if (storedIdevent) {
        navigate(`/redirect/${storedIdevent}`);
      } else {
        navigate('/');
      }
      return;
    }

    // Réinitialiser le flag après utilisation
    sessionStorage.removeItem('hasPassedRedirect');
    if (storedIdevent) {
      setIdevent(storedIdevent);
      // Optionnel : Effacer l'idevent du sessionStorage après l'avoir récupéré
      sessionStorage.removeItem('currentIdevent');
    }
  }, [navigate]);

  /* const setDestUrl = useCallback(() => {
    if (annonce && ref) {
      const url = WinnerUrl + ref + "%20Annonce%20%3A%20" + encodeURIComponent(annonce.description);
      setDestination(isWinner ? url : annonce.url_des);
    }
  }, [isWinner, ref, annonce]); */

  useEffect(() => {
    if (document.readyState === "complete") {
      setLoading(false);
    } else {
      window.onload = () => {
        setLoading(false);
        /* console.log("La page est complètement chargée"); */
      };
    }
  }, []);

  const onDestClick = useCallback(() => {
    if (annonce) {
      client.post("/gagner", {
        idAnncs: annonce.id_anncs,
        code_event: annonce.code_event,
        ref: ref,
      });
  
      // Redirection dynamique
      if (isWinner && annonce.url_des) {
        window.open(annonce.url_des, "_blank", "noopener noreferrer");
      } else if (annonce.url_des) {
        window.open(annonce.url_des, "_blank", "noopener noreferrer");
      }
    }
  }, [annonce, ref, isWinner]);

  useEffect(() => {
    if (idevent) {
      client
        .get(`/select/aleatoire/annonce/${idevent}`)
        .then((res) => {
          const data = res.data.data;
          setAnnonce(data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [idevent]);

  useEffect(() => {
    if (annonce) {
      setIsWinner(annonce.type_anncs === 1);
      setMedias(annonce.media);
      SetStat(annonce.code_event, annonce.id_anncs);

      //si annonce gagnante, génère une ref
      if (annonce.type_anncs === 1) {
        client
          .get("generateur/ref")
          .then((res) => {
            setRef(res.data.data.ref);
            useCookie.setCookie("annonce", annonce);
          })
          .catch((err) => console.error(err));
      } else {
        setDestination(annonce.url_des);

      }
    }
  }, [annonce]);

  useEffect(() => {
    setDestUrl();
  }, [setDestUrl, annonce, ref]);

  useEffect(() => {
    if (medias && medias.length > 0) {
      setAddEl(
        <img
          src={process.env.REACT_APP_API_BASE_URL + "/" + medias[0].url_med}
          alt="annonce"
          className="scangif p-0 img-fluid"
          /* onClick={onDestClick} */
        />
      );
    }
  }, [medias, onDestClick]);

  useEffect(() => {
    if (isWinner) {
      confetti({
        particleCount: 150,
        spread: 70,
        origin: { y: 0.6 },
        colors: ['#fbbd08', '#ffd700', '#ffec8b']
      });
    }
  }, [isWinner]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsButtonDisabled(false);
      setButtonColor("red");
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  //Pour sauvegarder les annonces

  const user = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null;


  const handleSaveAd = async () => {
    if (!annonce) return; // Verifie si il y a une annoce a sauvegarder

    try {
      const response = await customClient.post(`/favoris/${user.id}`, { id_annonce: annonce.id_anncs });
      
      if (response.data.success) {
        setBookmarked(true); // Marquer comme sauvegarder
        alert(response.data.msg); // Montre un message d'alert
      } else {
        alert("Could not save the ad. Please try again.");
      }
    } catch (error) {
      console.error("Error saving ad:", error);
      alert("An error occurred while saving the ad.");
    }
  };


  return (
    <div className="text-center">
      {loading ? (
        <ClipLoader loading={loading} size={80} />
      ) : (
        <div className="card bg-transparent text-center border-0">
          <div className="card-header bg-transparent border-0">
            <h3 className="uniform-size pseudo-button">
              Regarde bien l'image
            </h3>
          </div>

          <div className="mb-2 d-flex flex-column justify-content-center align-items-center">
            <div className="text-center">
              {!annonce || annonce.code_event === "default" ? (
                <img
                  src={process.env.REACT_APP_API_BASE_URL + "/" + (medias && medias[0] ? medias[0].url_med : '')}
                  alt="annonce"
                  className="scangif p-0 img-fluid"
                  style={{
                    width: '280px',   // Default width
                    height: 'auto',    // Keeps aspect ratio
                    maxWidth: '100%',  // Ensures responsiveness on smaller screens
                  }}
                  /* onClick={onDestClick} */
                />
              ) : (
                addEl
              )}
            </div>
          </div>

          {/* Section des icônes */}
          <div className="d-flex justify-content-between align-items-center mt-3 icon-bar w-100 px-4">
            <div className="d-flex">
              <i
                className={`${liked ? 'fas' : 'far'} fa-heart icon mx-2`}
                style={{ fontSize: '24px', cursor: 'pointer', color: liked ? 'red' : 'black' }}
                onClick={() => setLiked(!liked)}
              ></i>
              <NavLink to="/scanpage">
              <i
                className="fas fa-redo fa-pulse fa-flip-horizontal icon mx-2"
                style={{ fontSize: '24px', cursor: 'pointer', color:'black' }}
                /* onClick={() => window.location.reload()} */
                
              ></i>
              </NavLink>
            </div>

            {/* Icon à droite */}
            <i
              className={`${bookmarked ? 'fas' : 'far'} fa-bookmark icon mx-2`}
              style={{ fontSize: '24px', cursor: 'pointer', color: bookmarked ? 'blue' : 'black' }}
              onClick={handleSaveAd}
            ></i>
          </div>

          <ButtonPrimary 
        
            /* toUrl="/scanpage" */ 
            text="CONTINUER" 
            /* action={() => console.log('Button clicked')}  */
            action={onDestClick}
            disabled={isButtonDisabled}
          />
        </div> 
      )}
    </div>
  );
};

export default Place;